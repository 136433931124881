<template>
  <nav class="nav">
    <ul>
      <li v-if="$store.getters.isAuth">
        <router-link :to="{name: 'Telecast'}" class="button">Трансляция</router-link>
      </li>
      <li>
        <router-link :to="{name: 'Home', hash: '#section-program'}" v-if="$route.name !== 'Home'" class="button">
          Программа
        </router-link>
        <a href="#" v-scroll-to="'#section-program'" v-else class="button">Программа</a>
      </li>
      <!--      <li>-->
      <!--        <router-link :to="{name: 'Home', hash: '#section-partners'}" v-if="$route.name !== 'Home'"  class="button">Партнеры-->
      <!--        </router-link>-->
      <!--        <a href="#" v-scroll-to="'#section-partners'" v-else  class="button">Партнеры</a>-->
      <!--      </li>-->

      <!--      <li>-->
      <!--        <router-link :to="{name: 'Material'}"  class="button">Материалы партнеров</router-link>-->
      <!--      </li>-->
      <li v-if="$store.getters.isAuth">
        <router-link :to="{name: 'ConferenceMaterials'}" class="button">Материалы конференции</router-link>
      </li>
      <!--      <li v-if="$store.getters.isAuth">-->
      <!--        <router-link :to="{name: 'Votes'}" class="button">Опросы</router-link>-->
      <!--      </li>-->
      <li>
        <a href="https://yadi.sk/d/GiL6HqW3v1RG6g" target="_blank" class="button">Фотогалерея</a>
      </li>
      <li>
        <router-link :to="{name: 'Home', hash: '#section-faq'}" v-if="$route.name !== 'Home'" class="button">FAQ
        </router-link>
        <a href="#" v-scroll-to="'#section-faq'" v-else class="button">FAQ</a>
      </li>
      <!--      <li>-->
      <!--        <a href="#" v-scroll-to="'#section-votes'">Опросы</a>-->
      <!--      </li>-->
      <!--      <li v-if="$store.getters.isAuth">-->
      <!--        <a href="#" class="exit" @click.prevent="logout">-->
      <!--          Выйти-->
      <!--        </a>-->
      <!--      </li>-->
    </ul>
  </nav>
</template>

<script>
export default {
  name: "MenuTop",
  methods: {
    logout() {
      this.$cookie.delete('token')
      this.$store.commit('changeUser')
      this.$cookie.delete('rememberToken')
    }
  }
}
</script>

<style scoped lang="scss">
.nav {
  //position: absolute;
  top: 25px;
  right: 0;
  z-index: 10;
  @include media(992) {
    padding-top: 20px;
  }

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex-wrap: nowrap;
    @include media(992) {
      flex-wrap: wrap;
    }

    li {
      padding: 0 rem(3);


      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      @include media(992) {
        width: 50%;
        padding: 5px;
        &:first-child {
          padding-left: 5px;
        }

        &:last-child {
          padding-right: 5px;
        }
      }

      a {
        text-decoration: none;
        //color: #fff;
        font-weight: 500;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 110px;
        padding-left: 6px;
        text-align: center;
        padding-right: 6px;
        font-size: 12px;
        @include media(992) {
          width: 100%;
          line-height: 1.1;
        }

        //&:hover {
        //  &::before {
        //    content: '';
        //    position: absolute;
        //    top: 100%;
        //    margin-top: rem(10);
        //    background: #59B980;
        //    box-shadow: 0px 1px 1px #58FF9C;
        //    border-radius: 1px;
        //    height: 2px;
        //    left: 0;
        //    right: 0;
        //  }
        //}
      }

      .exit {
        //margin-left: 8vw;
        @include media(992) {
          display: none;
        }
      }
    }
  }
}
</style>
