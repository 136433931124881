<template>
  <div class="top" :class="getShowForm ? 'active' : ''">
    <div class="container">
      <div class="row d-flex align-items-stretch">
        <div class="col-12 col-md-12">
          <!--          <menu-top v-if="$store.getters.isAuth"></menu-top>-->
          <div
              class="top__logo d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row">
            <div>
              <router-link :to="{name: 'Home'}">
                <svg width="270" height="72" viewBox="0 0 270 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_202_1400)">
                    <path
                        d="M45.4473 55.1117H35.1689C33.5117 55.1117 31.8835 55.5609 30.4591 56.4119L6.46582 70.7546L7.20259 71.9982L25.5236 66.7571C27.1645 66.2878 28.8636 66.0486 30.57 66.0486H50.0462C51.7526 66.0486 53.4518 66.2878 55.0927 66.7571L73.4119 71.9982L74.1486 70.7546L50.1554 56.4119C48.731 55.5609 47.1028 55.1117 45.4455 55.1117H45.4473Z"
                        fill="#00C3F5"/>
                    <path
                        d="M27.3078 43.9392L32.9637 34.5272C33.8296 33.0864 34.2862 31.4373 34.2862 29.7536V0H32.8436L27.8827 21.6144C27.4479 23.5063 26.7202 25.3179 25.7233 26.9833L16.4527 42.471C15.6887 43.7457 14.7754 44.9254 13.7312 45.9809L0 59.8487L0.733132 61.085L24.1333 47.1058C25.4359 46.3279 26.5256 45.2395 27.3078 43.9374V43.9392Z"
                        fill="#00C3F5"/>
                    <path
                        d="M64.1626 42.471L54.892 26.9833C53.8951 25.3197 53.1674 23.5063 52.7326 21.6144L47.7717 0H46.3291V29.7536C46.3291 31.4355 46.7857 33.0864 47.6517 34.5272L53.3075 43.9392C54.0898 45.2413 55.1794 46.3297 56.482 47.1076L79.8822 61.0869L80.6153 59.8506L66.8859 45.9827C65.8417 44.9272 64.9266 43.7493 64.1626 42.4728V42.471Z"
                        fill="#00C3F5"/>
                    <path
                        d="M141.209 24.2733L137.867 27.6279C136.808 28.6981 136.213 30.1462 136.215 31.6546V39.8961C136.213 41.4063 136.808 42.8545 137.872 43.9228L141.221 47.2518C142.284 48.322 143.728 48.9228 145.233 48.9155H162.981V42.2445H146.268C145.866 42.2445 145.482 42.0856 145.198 41.8008L143.319 39.9143C143.037 39.6276 142.879 39.2423 142.877 38.8406V32.6809C142.877 32.281 143.035 31.8975 143.319 31.6144L145.198 29.7225C145.482 29.4395 145.866 29.2788 146.268 29.2788H162.981V22.6078H145.218C143.714 22.6023 142.269 23.2013 141.207 24.2715L141.209 24.2733Z"
                        fill="#1D202B"/>
                    <path
                        d="M226.971 24.2733C225.909 23.2032 224.464 22.6042 222.96 22.6097H200.898V48.9155H207.544V43.6671H222.92C224.424 43.6708 225.869 43.0718 226.931 42.0035L229.338 39.5948C230.397 38.5246 230.991 37.0765 230.99 35.5681V30.6831C230.997 29.1911 230.418 27.7558 229.378 26.6911L226.971 24.2751V24.2733ZM224.384 34.54C224.384 34.9399 224.224 35.3234 223.942 35.6064L223.007 36.5524C222.723 36.8354 222.339 36.9961 221.937 36.9961H207.591V29.2715H221.937C222.339 29.2715 222.723 29.4304 223.007 29.7152L223.942 30.6612C224.224 30.9442 224.382 31.3277 224.384 31.7277V34.54Z"
                        fill="#1D202B"/>
                    <path d="M168.852 29.2789H178.764V48.9137H185.41V29.2789H195.33V22.6079H168.852V29.2789Z"
                          fill="#1D202B"/>
                    <path
                        d="M118.447 22.6426H112.986C111.205 22.6371 109.575 23.6452 108.782 25.2467L96.8623 48.9156H104.31L107.112 43.3659H124.319L127.126 48.9484H134.567L122.655 25.2467C121.854 23.6525 120.228 22.6463 118.451 22.6426H118.447ZM110.464 36.6547L113.861 29.8979C114.052 29.5163 114.443 29.2734 114.87 29.2734H116.562C116.988 29.2734 117.379 29.5163 117.572 29.8979L120.968 36.6547H110.466H110.464Z"
                        fill="#1D202B"/>
                    <path
                        d="M258.108 25.2449C257.314 23.647 255.688 22.6371 253.909 22.6408H248.44C246.663 22.6444 245.037 23.6506 244.236 25.2449L232.324 48.9466H239.765L242.592 43.364H259.799L262.606 48.9466H270L258.108 25.2449ZM245.934 36.6529L249.335 29.8961C249.526 29.5126 249.918 29.2697 250.345 29.2715H252.03C252.457 29.2697 252.848 29.5126 253.039 29.8961L256.428 36.6529H245.934Z"
                        fill="#1D202B"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_202_1400">
                      <rect width="270" height="72" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>

              </router-link>
            </div>
            <div>
              <menu-top/>
            </div>
            <!--            <div class="top__button top__button_faq">-->
            <!--              <a class="button" href="#" v-scroll-to="'#section-faq'">FAQ</a>-->
            <!--            </div>-->
          </div>
          <div class="top__content">
            <div class="top__date">
              {{ getDateStart }}
            </div>
            <div class="top__title">
              {{ $store.getters.getEvent.name || 'АСТРАКОНФ-2023' }}
            </div>
            <template v-if="$store.getters.getEvent.description">
              <div class="top__desc" v-html="$store.getters.getEvent.description"></div>
            </template>
            <template v-else>
              <div class="top__desc">
                <p><b class="text-uppercase">5-я ежегодная практическая конференция</b></p>
                <p>Мероприятие ориентировано на представителей бизнеса и государственных организаций, госкорпораций и
                  концернов. Посвящено цифровизации, ИТ-импортозамещению, проектам на базе операционной системы Astra
                  Linux и решений из продуктового портфеля ГК «Астра». Цели конференции — выстраивание предметного
                  диалога
                  между компаниями из различных секторов экономики, регуляторами и ведущими российскими разработчиками
                  софта и «железа», а также совместный поиск оптимальных путей для ИТ-трансформации.</p>
              </div>
            </template>

            <div class="top__buttons">
              <div class="top__button top__button_accent" v-if="!$store.getters.isAuth">
                            <span class="button button_accent"
                                  @click.prevent="$store.commit('changeRightModal', 'login')">Войти</span>
              </div>
              <div class="top__button" v-if="!$store.getters.isAuth">
                <span class="button" @click.prevent="$store.commit('changeRightModal', 'register')">Регистрация</span>
              </div>
              <div class="top__button" v-if="$store.getters.isAuth"
                   :style="$store.getters.isAuth ? 'margin-left: 0' : ''">
                <router-link :to="{name: 'Telecast'}" class="button">Трансляция</router-link>
              </div>
            </div>
          </div>

        </div>
        <!--        <div class="col-12 col-md-4 d-md-flex align-items-center justify-content-end">-->
        <!--          <home-slider-->
        <!--              v-if="$store.getters.getContentByType('slider') && $store.getters.getContentByType('slider').length"></home-slider>-->
        <!--          &lt;!&ndash;                    <home-contact></home-contact>&ndash;&gt;-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
// import HomeContact from "./HomeContact";
// import HomeSlider from "./HomeSlider";
import moment from 'moment-timezone'
import MenuTop from "../Layout/Menu";

export default {
  name: "HomeTop",
  components: {
    MenuTop,
    // HomeSlider,
    // HomeContact
  },
  data() {
    return {
      showLogin: false,
      showRegister: true
    }
  },
  computed: {
    getShowForm() {
      return this.$store.getters.getRightModal
    },
    getDateStart() {
      if (this.$store.getters.getEvent) {
        moment.locale('ru')
        return moment(this.$store.getters.getEvent.dateStart).tz('Europe/Moscow').format('DD MMMM YYYY') + ' года'
      } else {
        return null
      }
    }
  },
}
</script>

<style scoped lang="scss">
.top {
  min-height: 70vh;
  //background-color: #063B67;
  //background-image: url(~@/assets/img/home.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @include media(768) {
    height: auto;
  }

  &__content {
    padding: 10vh 0 5vh;
    @include media(992) {
      padding: 5vh 0;
    }
  }

  &::before {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    //background: linear-gradient(180deg, rgba(0, 36, 79, 0.51) 0%, rgba(217, 217, 217, 0) 100%);
    mix-blend-mode: darken;
  }


  .container {
    position: relative;
    height: 100%;
  }

  &__logo {
    padding-top: 4vh;

    img {
      max-width: 110px;
      min-width: 80px;
      width: 30vw;
      vertical-align: top;
      @include media(1919) {
        max-width: 110px;
      }
      @include media(1439) {
        max-width: 110px;
      }
      @include media(1280) {
        max-width: 90px;
      }
      @include media(1200) {
        max-width: 70px;
      }
      @include media(1110) {
        max-width: 80px;
      }
      @include media(1023) {
        max-width: 95px;
      }
      @include media(480) {
        max-width: 70px;
      }

    }

    svg {
      @include media(992) {
        max-width: 140px;
        height: auto;
      }
      @include media(480) {
        max-width: 120px;
        height: auto;
      }
    }
  }

  &__absolute {
    position: absolute;
    top: rem(20);
    right: 0;
    @include media(768) {
      right: rem(10);
    }

    svg {
      @include media(1919) {
        max-width: 180px;
        height: auto;
      }
      @include media(1439) {
        max-width: 130px;
      }
      @include media(1023) {
        max-width: 95px;
      }

    }
  }

  &__date {
    font-size: rem(20);
    font-family: PTAstraFact, sans-serif;
    color: #071C27;
    font-weight: bold;
    //display: none;
    @include media(768) {
      font-size: rem(21);
      padding-top: rem(10);
    }
  }

  &__title {
    color: #071C27;
    font-weight: 900;
    text-transform: uppercase;
    font-size: rem(56);
    font-family: PTAstraFact, sans-serif;
    line-height: 1.3;
    padding-top: 2vh;
    //@include media(1919) {
    //  font-size: rem(32);
    //}
    @include media(1280) {
      //padding-top: rem(10);
      font-size: rem(48);
      //font-size: rem(18);
    }
    @include media(992) {
      font-size: rem(42);
    }
    @include media(768) {
      font-size: rem(36);
      //padding-top: rem(10);
    }
  }

  &__desc {
    padding-top: 1vh;
    color: #292929;
    font-size: rem(16);
    font-weight: 400;
    line-height: 1.5;
    max-width: 825px;
    font-family: PTAstraFact, sans-serif;

    b {
      font-size: rem(24);
    }

    h1 {
      padding-bottom: 20px;
    }

    p {
      &:first-child {
        margin-bottom: 4px;
      }
    }

    &::v-deep {
      b {
        font-weight: 600;
      }

      .ql-size-small {
        font-size: rem(10);
        line-height: 1.1;
        @include media(768) {
          font-size: rem(12);
        }
      }

      strong {
        font-weight: 700;
        color: #071C27;
        text-transform: uppercase;
      }
    }


    @include media(992) {
      font-size: rem(16);
    }
    @include media(768) {
      font-size: rem(14);
    }
  }

  &__buttons {
    padding-top: 6vh;
    padding-bottom: 4vh;
    //display: flex;
  }

  &__button {
    max-width: 264px;
    width: 100%;
    padding-bottom: rem(15);

    span {
      border: 1px solid #0079C1;
    }

    &:last-child {
      //margin-left: rem(20);
    }

    @include media(1280) {
      padding-top: rem(15);
      .button {
        height: 38px;
      }
    }
    @include media(992) {
      .button {
        height: 48px;
      }
    }
    @include media(768) {
      padding-top: rem(7);
      //margin-left: auto;
      //margin-right: auto;
    }

    &_faq {
      max-width: 183px;
      padding-bottom: 0;
      margin-bottom: 0;
      @include media(768) {
        max-width: 120px;
        margin: 0;
      }
    }

    &_accent {
      .button {
        background: #0079C1;
        color: #ffffff;

        &:hover {
          background: darken(#0079C1, 5%);
        }

      }
    }
  }

}
</style>
