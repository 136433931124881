<template>
  <div class="partners" id="section-partners">
    <partner-section v-for="(val, index, key ) in getPartnersToData" :key="key" :partners="val"
                     :title="index"></partner-section>
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <div class="partners__button">
<!--            <router-link :to="{name: 'Material'}" class="button button_blue">-->
<!--              Материалы партнеров-->
<!--            </router-link>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import PartnerSection from "./PartnerSection";
import partnersData from '../../static/partner.json'

export default {
  name: "Partners",
  components: {PartnerSection},
  data() {
    return {
      partners: [],
    }
  },
  computed: {
    getPartnersToData() {
      let partners = _.filter(this.partners, partner => !partner.hideToIndex)
      partners = _.groupBy(partners, 'group')
      return {
        'Стратегические партнеры': partners['Стратегические партнеры'],
        'Партнеры': partners['Партнеры'],
        'Информационные партнеры': partners['Информационные партнеры'],
      }
    }
  },
  mounted() {
    this.partners = partnersData
    // this.$axios.get('/partner')
    //     .then(resp => {
    //       this.partners = resp.data
    //     })
    //     .catch(e => {
    //       console.log(e)
    //     })
  }
}
</script>

<style scoped lang="scss">
.partners {
  padding-top: rem(15);
}

.partners__button {
  max-width: 280px;
  margin: rem(20) auto 0;
}
</style>
