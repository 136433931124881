<template>
  <div class="callback callback_register" @click="clickToWrap">
    <div class="callback__wrapper">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-12">
            <div class="callback__top">
              <h2 class="callback__title">РЕГИСТРАЦИЯ</h2>
              <div class="callback__desc">
                Все поля формы обязательны для заполнения. Организатор оставляет за собой право отказать в регистрации.
              </div>
            </div>

            <validation-observer tag="form" v-slot="{ invalid, valid }" @submit.prevent="sendForm"
                                 class="callback__form form">
              <div class="row">
                <div class="form__wrap" v-for="(item, index) in form" :key="index" :class="item.class">
                  <form-phone v-if="item.typeForm === 'input' && item.type === 'phone'" :form="item"
                              :index="index"
                              @changeVal="changeVal"/>
                  <form-email v-else-if="item.typeForm === 'input' && item.type === 'email'" :unique="uniqueEmail"
                              :success="successReg"
                              @changeVal="changeVal" :index="index" :form="item"
                  />
                  <validation-provider tag="div" :rules="item.rules" v-slot="{errors, touched}" class="form__item"
                                       v-else-if="item.name === 'password'"
                                       :name="item.nameRus"
                                       vid="password"
                  >
                    <input :type="item.type" v-model="item.value"
                           :placeholder="item.placeholder" :name="item.name" :value="item.value" class="form__input"
                           :class="errors.length && !successReg && touched ? 'form__input_error' : ''">
                    <div class="form__error" v-if="errors.length && !successReg && touched">{{ errors[0] }}</div>
                  </validation-provider>
                  <validation-provider tag="div" :rules="item.rules" v-slot="{errors, touched}" class="form__item"
                                       v-else-if="item.name === 'passwordCompare'"
                                       data-vv-as="password"
                                       :name="item.nameRus"

                  >
                    <input :type="item.type" v-model="item.value"
                           :placeholder="item.placeholder" :name="item.name" :value="item.value" class="form__input"
                           :class="errors.length && !successReg && touched ? 'form__input_error' : ''">
                    <div class="form__error" v-if="errors.length && !successReg && touched">{{ errors[0] }}</div>
                  </validation-provider>
                  <form-input v-else-if="item.typeForm === 'input'" :form="item" :index="index"
                              :success="successReg"
                              :unique="uniqueEmail"
                              @changeVal="changeVal"></form-input>
                  <form-select v-else-if="item.typeForm === 'select'" :form="item" :index="index"
                               :success="successReg"
                               @changeVal="changeVal"></form-select>
                  <validation-provider tag="div" :rules="item.rules" v-slot="{errors}" class="form__item"
                                       v-else-if="item.type === 'checkbox'"
                                       :name="item.nameRus"
                  >
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" :name="item.name" v-model="form[index].value">
                      <label class="form-check-label">
                        Я прочитал и согласен с
                        <a href="https://astralinux.ru/user-agreement/" target="_blank">политикой хранения и
                          обработки персональных данных</a>
                      </label>
                    </div>
                    <div class="form__error" v-if="errors.length && !successReg">{{ errors[0] }}</div>
                  </validation-provider>
                </div>
                <div class="callback__footer">
                  <div class="col-12 col-md-12">
                    <div class="form__button">
                      <div class="row d-flex align-items-center">
                        <div class="col-12 col-sm-12">
                          <button class="button button_blue"
                                  :disabled="!valid">Завершить регистрацию
                          </button>
                        </div>
                      </div>
                    </div>
                    <!--                    <hr class="callback__hr">-->
                    <!--                    <div class="callback__change">-->
                    <!--                      Вы уже зарегистрировались?-->
                    <!--                      <a href="#" @click.prevent="$store.commit('changeRightModal', 'login')">Войти-->
                    <!--                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"-->
                    <!--                             xmlns="http://www.w3.org/2000/svg">-->
                    <!--                          <path d="M1 7H13" stroke="#00476D" stroke-width="2"-->
                    <!--                                stroke-linecap="round" stroke-linejoin="round"/>-->
                    <!--                          <path d="M7.75 1.75L13 7L7.75 12.25" stroke="#00476D"-->
                    <!--                                stroke-width="2" stroke-linecap="round"-->
                    <!--                                stroke-linejoin="round"/>-->
                    <!--                        </svg>-->
                    <!--                      </a>-->
                    <!--                    </div>-->
                  </div>

                </div>
              </div>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from "../components/form/FormInput";
import FormPhone from "../components/form/FormPhone";
import FormSelect from "../components/form/FormSelect";
import FormEmail from "../components/form/FormEmail";

export default {
  name: "Register",
  components: {FormEmail, FormSelect, FormPhone, FormInput},
  data() {
    return {
      // verifyPhone: false,
      successReg: false,
      uniqueEmail: null,
      form: [
        {
          name: 'phone',
          type: 'phone',
          value: '',
          rules: 'required',
          nameRus: 'Телефон',
          typeForm: 'input',
          placeholder: 'Укажите свой номер  телефона',
          class: 'col-12'
        },
        {
          name: 'email',
          type: 'email',
          value: '',
          rules: 'required',
          nameRus: 'E-mail',
          typeForm: 'input',
          placeholder: 'E-mail',
          class: 'col-12'
        },
        {
          name: 'lastName',
          type: 'text',
          value: '',
          rules: 'required',
          nameRus: 'Фамилия',
          typeForm: 'input',
          placeholder: 'Фамилия',
          class: 'col-12'
        },
        {
          name: 'firstName',
          type: 'text',
          value: '',
          rules: 'required',
          nameRus: 'Имя',
          typeForm: 'input',
          placeholder: 'Имя',
          class: 'col-12'
        },
        {
          name: 'middleName',
          type: 'text',
          value: '',
          rules: 'required',
          nameRus: 'Отчество',
          typeForm: 'input',
          placeholder: 'Отчество',
          class: 'col-12'
        },

        {
          name: 'organization',
          type: 'text',
          value: '',
          rules: 'required',
          nameRus: 'Организация',
          typeForm: 'input',
          placeholder: 'Организация',
          class: 'col-12'
        },
        {
          name: 'post',
          type: 'text',
          value: '',
          rules: 'required',
          nameRus: 'Должность',
          typeForm: 'input',
          placeholder: 'Должность',
          class: 'col-12'
        },
        {
          name: 'country',
          type: 'text',
          value: '',
          rules: 'required',
          nameRus: 'Страна',
          typeForm: 'input',
          placeholder: 'Страна',
          class: 'col-12 col-md-12'
        },
        {
          name: 'city',
          type: 'text',
          value: '',
          rules: 'required',
          nameRus: 'Город',
          typeForm: 'input',
          placeholder: 'Город',
          class: 'col-12 col-md-12'
        },
        // {
        //   name: 'password',
        //   type: 'password',
        //   value: '',
        //   rules: 'required',
        //   nameRus: 'Пароль',
        //   typeForm: 'input',
        //   placeholder: 'Пароль',
        //   class: 'col-12 col-md-6'
        // },
        // {
        //   name: 'passwordCompare',
        //   type: 'password',
        //   value: '',
        //   rules: 'required|confirmed:password',
        //   nameRus: 'Повторение пароля',
        //   typeForm: 'input',
        //   placeholder: 'Повторите пароль',
        //   class: 'col-12 col-md-6'
        // },
        {
          name: 'online',
          type: 'select',
          value: 2,
          rules: 'required',
          nameRus: 'Тип участия',
          typeForm: 'select',
          placeholder: 'Выберите тип участия',
          class: 'col-12 col-md-12',
          values: this.$route.query.register && this.$route.query.register === 'offline' ? [
            {
              nameRus: 'Онлайн',
              value: 2
            },
            {
              nameRus: 'Офлайн',
              value: 3
            }

          ] : [
            {
              nameRus: 'Онлайн',
              value: 2
            }
          ]
        },
        {
          name: 'policy',
          type: 'checkbox',
          value: null,
          rules: 'required:true',
          nameRus: 'Согласие с политикой'
        }
      ]
    }
  },
  computed: {
    getEmail() {
      return this.form[4].value
    }
  },
  methods: {
    clickToWrap(e) {
      if (e.target.classList.contains('callback')) {
        this.$store.commit('changeRightModal', null)
      }
    },
    sendForm() {
      this.uniqueEmail = null
      let data = {}
      for (let item of this.form) {
        data[item.name] = item.value
      }
      this.$axios.post('/auth/unique', data)
          .then(() => {
            this.$axios.post('/auth/register', data)
                .then(resp => {
                  console.log(resp)
                  this.$store.commit('changeRightModal', null)
                  this.successReg = true
                  this.$modal.show('good-register')
                  for (let item in this.form) {
                    this.form[item].value = ''
                  }
                })
                .catch(e => {
                  console.log(e.response)
                  this.$notify({
                    type: 'error',
                    text: e.response.data.message
                  })
                })
          })
          .catch(() => {
            this.uniqueEmail = true
          })
    },
    changeVal(e) {
      this.form[e.index].value = e.val
    }
  },
  watch: {
    getEmail() {
      this.uniqueEmail = null
    }
  },
  mounted() {
    this.successReg = false
  }
}
</script>

<style scoped lang="scss">
.callback {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  z-index: 100;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.26);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5vh 0;

  .form-check {
    font-size: rem(12);
  }

  &__wrapper {
    position: relative;
    max-height: 100%;
    overflow: auto;
    background: #FFFFFF;
    opacity: 0.95;
    box-shadow: 0px 0px 10px 1px rgba(45, 180, 239, 0.5);
    border-radius: 10px;
    max-width: 305px;
    padding: 0px 10px;
  }

  &__top {
    position: sticky;
    top: 0;
    padding-top: 25px;
    background-color: #fff;
    z-index: 3;
  }

  &__logo {
    position: absolute;
    top: 0;
    right: 0;
    @include media(992) {
      display: none;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: rem(20);
    text-align: center;
    padding-bottom: 10px;

    color: #000000;
    @include media(768) {
      font-size: rem(32);
    }
  }

  &__desc {
    max-width: 625px;
    font-style: normal;
    font-weight: 300;
    font-size: rem(14);
    line-height: 1.3;
    color: #292929;
  }

  &__close {
    font-style: normal;
    font-weight: 300;
    font-size: rem(14);
    color: #393939;
    text-decoration: none;
    @include media(600) {
      margin-top: rem(10);
      display: inline-block;
    }
  }

  &__footer {
    position: sticky;
    bottom: 0;
    z-index: 10;
    @media screen and (max-height: 1079px) {
      position: relative;
      padding-bottom: rem(20);
    }
  }

  &__hr {
    background-color: #7F7F7F;
    border: none;
    height: 2px;
    margin: 20px 0;
  }

  &__change {
    font-style: normal;
    font-weight: normal;
    font-size: rem(16);
    color: #000000;

    a {
      text-decoration: none;
      color: #00476D;
    }
  }
}
</style>
