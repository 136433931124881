<template>
  <modal name="good-register" class="fail-login" :adaptive="true" :height="'auto'" :maxWidth="341">
    <div class="popup__wrapper">
      <div class="popup__title">Спасибо!<br/>
        Вы успешно подали заявку
      </div>
      <div class="popup__desc">
        <p>Спасибо за регистрацию. Ожидайте подтверждение администрации.</p>
      </div>
      <div class="popup__button">
        <button class="button button_blue" @click="$modal.hide('good-register')">ок</button>
      </div>
    </div>

  </modal>
</template>

<script>
export default {
  name: "GoodRegister"
}
</script>

<style scoped lang="scss">
.fail-login {
  &::v-deep {
    .vm--modal {
      border-radius: 10px;
    }
  }
}
</style>