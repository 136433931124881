import Vue from 'vue'
import Vuex from 'vuex'
// import axios from "../plugins/axios"
import _ from 'lodash'
import moment from "moment-timezone";
import eventData from '../static/event.json'
import contentData from '../static/content.json'
import clientData from '../static/client.json'
import programData from '../static/program.json'


Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null,
        rightModal: null,
        event: null,
        settings: null,
        content: null,
        program: [],
        deleteMessage: false,
    },
    getters: {
        getUser(state) {
            return state.user
        },
        getDeleteMessage(state) {
            return state.deleteMessage
        },
        getProgramNoFilter(state) {
            const program = _.forEach(state.program, item => {
                const date = item.time.split('–')
                item.timeStart = date[0].trim()
                item.timeEnd = date[1].trim()
            })
            return program
        },
        getProgram(state) {
            if (state.program) {
                let arr = []
                for (let i in state.program) {
                    const program = state.program[i]
                    const date = program.time.split('-')
                    if (date.length > 1) {
                        program.timeStart = date[0].trim()
                        program.timeEnd = date[1].trim()
                    }
                }
                const programSort = _.sortBy(state.program, item => {
                    return moment(item.timeStart, 'HH-mm').tz('Europe/Moscow').format('YYYY-MM-DD HH:mm')
                })
                for (let program of programSort) {
                    let findDay = _.findIndex(arr, item => item.id === program.day_id)
                    if (findDay === -1) {
                        arr.push({
                            id: program.day_id,
                            name: program.programDay.name,
                            sections: []
                        })
                        findDay = arr.length - 1
                    }
                    let findSection = _.findIndex(arr[findDay].sections, item => {
                        return item.id === program.section_id
                    })
                    if (findSection === -1) {
                        arr[findDay].sections.push({
                            id: program.section_id,
                            name: program.programSection.name,
                            theme: program.programSection.theme,
                            order: program.programSection.order,
                            program: []
                        })
                        findSection = arr[findDay].sections.length - 1
                    }
                    const contentLength = program.content.replace(/(<[^>]+>|<[^>]>|<\/[^>]>)/g, '').length
                    arr[findDay].sections[findSection].program.push({
                        content: contentLength > 1 ? program.content : '',
                        order: program.order,
                        name: program.name,
                        time: program.time,
                        id: program.id,
                        speaker: program.Speaker,
                        dayId: program.day_id,
                        sectionId: program.section_id,
                        timeStart: program.timeStart,
                        timeEnd: program.timeEnd,
                        presentation: program.presentation
                    })
                }
                for (let i in arr) {
                    arr[i].sections = _.sortBy(arr[i].sections, ['order'])
                }
                return arr
            }
            return []
        },
        isAuth() {
            // return !!state.user
            return true
        },
        getRightModal(state) {
            return state.rightModal
        },
        getEvent(state) {
            return state.event || {}
        },
        getSettings(state) {
            return state.settings
        },
        getSettingByValue(state) {
            return (name) => {
                const find = _.find(state.settings, item => item.name === name)
                if (find) {
                    return find.value
                } else {
                    return undefined
                }
            }
        },
        getContent(state) {
            return state.content
        },
        getContentByType(state) {
            return (type) => {
                if (state.content) {
                    let items = []
                    for (let item of state.content) {
                        if (item.type === type) {
                            items.push(item)
                        }
                    }
                    items = _.orderBy(items, ['order'])
                    return items
                } else {
                    return null
                }
            }
        }
    },
    mutations: {
        changeRightModal(state, payload) {
            return state.rightModal = payload
        },
        changeDeleteMessage(state, payload) {
            return state.deleteMessage = payload
        },
        changeUser(state, payload) {
            return state.user = payload
        },
        changeEvent(state, payload) {
            return state.event = payload
        },
        changeSettings(state, payload) {
            return state.settings = payload
        },
        changeContent(state, payload) {
            return state.content = payload
        },
        changeProgram(state, payload) {
            return state.program = payload
        }
    },
    actions: {
        loadEvent({commit}) {
            commit('changeEvent', eventData)
            // axios.get('/event')
            //     .then(resp => {
            //         commit('changeEvent', resp.data)
            //     })
            //     .catch(e => {
            //         console.log(e)
            //     })
        },
        loadContent({commit}) {
            commit('changeContent', contentData)
            // axios.get('/content')
            //     .then(resp => {
            //         commit('changeContent', resp.data)
            //     })
            //     .catch(e => {
            //         console.log(e)
            //     })
        },
        loadSettings({commit}) {
            commit('changeSettings', clientData)
            // axios.get('/setting/client')
            //     .then(resp => {
            //         commit('changeSettings', resp.data)
            //     })
            //     .catch(e => {
            //         console.log(e)
            //     })
        },
        loadProgram({commit}) {
            commit('changeProgram', programData)
            // axios.get('/program')
            //     .then(resp => {
            //         commit(
            //             'changeProgram',
            //             resp.data
            //         )
            //     })
            //     .catch(e => {
            //         console.log(e)
            //     })
        }
    },
    modules: {}
})
