<template>
  <div class="countdown" v-show="date > 0">
    <div class="countdown__text">
      До начала мероприятия осталось
    </div>
    <div class="countdown__wrapper d-flex ">
      <div class="countdown__item">
        <div class="countdown__circle">
          {{ days }}
        </div>
        <div class="countdown__info">
          День
        </div>
      </div>
      <div class="countdown__dots">
        :
      </div>
      <div class="countdown__item">
        <div class="countdown__circle">
          {{ hours }}
        </div>
        <div class="countdown__info">
          Час
        </div>
      </div>
      <div class="countdown__dots">
        :
      </div>
      <div class="countdown__item">
        <div class="countdown__circle">
          {{ minutes }}
        </div>
        <div class="countdown__info">
          Минут
        </div>
      </div>
      <div class="countdown__dots">
        :
      </div>
      <div class="countdown__item">
        <div class="countdown__circle">
          {{ seconds }}
        </div>
        <div class="countdown__info">
          Секунд
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'

export default {
  name: "HomeCountdown",
  data() {
    return {
      now: null,
      date: null,
      diff: 0,
      interval: null,
      end: '2022/04/21 10:00'
    }
  },
  computed: {
    seconds: function () {
      let time = (Math.trunc(this.diff) % 60).toString()
      return time.length === 2 ? time : `0${time}`;
    },
    minutes: function () {
      let time = (Math.trunc(this.diff / 60) % 60).toString()
      return time.length === 2 ? time : `0${time}`;
    },
    hours: function () {
      let time = (Math.trunc(this.diff / 60 / 60) % 24).toString()
      return time.length === 2 ? time : `0${time}`;
    },
    days: function () {
      let time = (Math.trunc(this.diff / 60 / 60 / 24)).toString()
      return time.length === 2 ? time : `0${time}`;
    },
  },
  watch: {
    now: function () {
      (this.diff = this.date - this.now), (this.diff <= 0 || this.stop) && ((this.diff = 0), clearInterval(this.interval));
    },
    stop: function (t) {
      if (t) {
        return clearInterval(this.interval)
      }
      return this.startCountdown()
    }
  },
  mounted() {
    this.now = moment.utc().add(3, 'hour').format('X')
    this.end = moment.utc(this.$store.getters.getEvent.dateStart, 'YYYY/MM/DD HH:mm').format('YYYY/MM/DD HH:mm')
    this.startCountdown()
  },
  destroyed: function () {
    clearInterval(this.interval);
  },
  methods: {
    startCountdown() {
      this.date = moment.utc(this.end, 'YYYY/MM/DD HH:mm').add(3, "hour").format('X')
      this.interval = setInterval(() => {
        this.now = moment.utc().add(3, 'hour').format('X')
      }, 1000);
    }
  }
}
</script>

<style scoped lang="scss">
.countdown {
  padding: 19px 0;
  @media screen and (max-height: 700px) and (max-width: 992px) {
    padding: 0;

  }
  &__wrapper {
    padding-top: 20px;
    display: flex;
    align-items: center;
    @media screen and (max-height: 700px) and (max-width: 992px) {
      padding: 0;

    }
    @include media(1919) {
      padding-top: rem(10);
    }
    @include media(768) {
      justify-content: center;
    }
  }
  &__dots {
    font-size: rem(64);
    color: #0079C1;
    margin: 0 14px;
    @include media(768) {
      font-size: rem(58);
      margin: 0 7px;
    }
  }

  &__item {

    &:last-child {
      margin-right: 0;
    }
  }

  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: rem(18);
    text-transform: uppercase;
    //padding-top: 20px;

    color: #292929;
    //@include media(1400) {
    //  font-size: rem(16);
    //}
    @include media(768) {
      text-align: center;
    }
  }

  &__circle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-size: rem(64);
    text-transform: uppercase;
    color: #0079C1;
    font-weight: 500;
    @include media(768) {
      font-size: rem(58);
    }
  }

  &__info {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: rem(10);
    color: #292929;
    line-height: 1;
  }
}
</style>